import React from "react"
import {
  mugShotItem,
  mugShotImage,
  mugShotFunctie,
  teamSocials,
  teamSocialLinkLinkedin,
  teamSocialLinkMail,
} from "./mug-shot.module.css"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaLinkedin } from "react-icons/fa"

const MugShot = ({ fileName, name, title, linkedIn, email }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          gatsbyImageData(layout: CONSTRAINED, width: 1600)
        }
      }
    }
  `)

  const image = allImageSharp.nodes.find(
    (n) => n.gatsbyImageData.images.fallback.src.includes(fileName)
  ).gatsbyImageData

  return (
    <div className={mugShotItem}>
      <div className={mugShotImage}>
        <figure>
          <GatsbyImage image={image} alt={name} style={{ width: "100%" }} />
        </figure>
      </div>{" "}
      {/* mug shot imag */}
      <h3>{name}</h3>
      <div className={mugShotFunctie}>{title}</div>
      <div className={teamSocials}>
        <a
          className={teamSocialLinkLinkedin}
          aria-label={`LinkedIn ${name}`}
          href={linkedIn}
          title={`LinkedIn ${name}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaLinkedin />
        </a>
        <a
          className={teamSocialLinkMail}
          aria-label={`Mail ${name}`}
          href={`mailto:${email}`}
          title={`Mail ${name}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Mail
        </a>
      </div>{" "}
      {/* team socials */}
    </div>
  )
}

export default MugShot
