import React from "react"
import {
  teamWrapper,
  teamIntro,
  mugShots,
  teamAside,
} from "./team.module.css"
import { graphql } from "gatsby"

/* Components */
import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import Footer from "../components/footer/footer"

/* Components */
import StickyNav from "../components/sticky-nav/sticky-nav"
import SideColumn from "../components/side-column/side-column"
import MugShot from "../components/mug-shot/mug-shot"

const MKBSDTeam = ({ data }) => {
  const callToActions = data.contentfulPage.callToActions
  const navigationDesks = data.navigationDesks.nodes;
  return (
    <Layout>
      <header className={"header"}>
        <StickyNav desks={navigationDesks} />
      </header>
      <main className="main">
        <div className={`container clearfix`}>
          <Seo title="Medewerkers van MKB Servicedesk" />
          <div className={teamWrapper}>
            <h1>Team MKBSD</h1>
            <div className={teamIntro}>
              <p>
                Het succes van MKB Servicedesk kan alleen mogelijk worden
                gemaakt door de constante inzet van onze enthousiaste collega’s
                en <a href="../kennispartners">klanten</a>. Samen mooie dingen
                maken. Elk teamlid vervult een eigen rol in zijn of haar
                specialisme. Daar zijn wij best trots op!{" "}
                <b>Daarom stellen we ze hier graag voor.</b>
              </p>
            </div>{" "}
            {/* team intro */}
            <div className={mugShots}>
              <MugShot
                fileName="rvandeaa.jpg"
                name="Renée van der Aa"
                title="business owner"
                email="r.vanderaa@vanspaendonck.nl"
                linkedIn="https://www.linkedin.com/in/ren%C3%A9e-van-der-aa-38185a10/"
              />
              <MugShot
                fileName="paul-te-kortschot.jpg"
                name="Paul te Kortschot"
                title="studio coördinator"
                email="paul@mkbservicedesk.nl"
                linkedIn="https://nl.linkedin.com/in/paultekortschot"
              />
              <MugShot
                fileName="jade-karthaus.jpg"
                name="Jade Karthaus"
                title="Hoofdredacteur"
                email="jade@mkbservicedesk.nl"
                linkedIn="https://www.linkedin.com/in/jade-karthaus-6265577b/"
              />
              <MugShot
                fileName="karin-van-rooij.jpg"
                name="Karin van Rooij"
                title="campagne manager"
                email="karin@mkbservicedesk.nl"
                linkedIn="https://www.linkedin.com/in/karinvanrooij/"
              />
              <MugShot
                fileName="marjolein-oosterbeek.jpg"
                name="Marjolein Oosterbeek"
                title="campagne manager"
                email="marjolein@mkbservicedesk.nl"
                linkedIn="https://www.linkedin.com/in/marjolein-oosterbeek-hendriks-03846137/"
              />
              <MugShot
                fileName="marlot-verdam.jpg"
                name="Marlot Verdam"
                title="Jr. Campagne/Project Manager"
                email="marlot@mkbservicedesk.nl"
                linkedIn="https://www.linkedin.com/in/marlot-verdam/"
              />
              <MugShot
                fileName="sander-nijmeijer.jpg"
                name="Sander Nijmeijer"
                title="sales manager"
                email="sander@mkbservicedesk.nl"
                linkedIn="https://www.linkedin.com/in/sandernijmeijer/"
              />
            </div>{" "}
            {/* mug shots */}
          </div>{" "}
          {/* team wrapper */}
          <SideColumn
            className={teamAside}
            callToActions={callToActions}
            pageContentfulId="team"
          />
        </div>{" "}
        {/* container */}
      </main>
      <Footer />
    </Layout>
  )
}
export default MKBSDTeam

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    navigationDesks: allContentfulDesk(sort: { fields: sortNavigation }) {
      nodes {
        id
        name
        slug2
        sortNavigation
        ctaNavigation {
          image {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 1180)
          }
          title
          slug
          categories {
            slug2
            desk {
              slug2
            }
          }
        }
        deskcategory {
          id
          name
          slug2
          desk {
            id
            name
            slug2
          }
          article {
            id
            title
            slug2
            updateDate
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
        }
      }
    }

    contentfulPage(slug: { eq: "team" }) {
      name
      callToActions {
        __typename
        ... on ContentfulExternalLink {
          contentful_id
          title
          url
          linkText
          showSidebarCtaImage
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 295, quality: 100)
          }
        }
      }
    }
  }
`
