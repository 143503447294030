// extracted by mini-css-extract-plugin
export var mugShotFunctie = "team-module--mug-shot-functie--b0d62";
export var mugShotImage = "team-module--mug-shot-image--f7adc";
export var mugShotItem = "team-module--mug-shot-item--926a8";
export var mugShots = "team-module--mug-shots--b969e";
export var teamAside = "team-module--teamAside--e9e98";
export var teamIntro = "team-module--team-intro--54fd5";
export var teamSocialLinkLinkedin = "team-module--team-social-link-linkedin--3a672";
export var teamSocialLinkMail = "team-module--team-social-link-mail--bea42";
export var teamSocials = "team-module--team-socials--aed0c";
export var teamWrapper = "team-module--team-wrapper--25053";